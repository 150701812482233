<template lang="pug">
  .home
    keep-alive
      router-view
    v-footer.white.elevation-3(app height="70")
      v-layout.mx-auto(style="max-width:600px")
        template(v-for="(tab, index) in tabs")
          div.d-flex.pa-2(align="center" :key="tab.value" @click="currentTab = tab; onTabChange(currentTab)")
            div.my-auto
              v-icon(:color="currentTab.value == tab.value ? 'primary' : 'textBlack'") {{ tab.icon }}
              .body-2.primary--text(v-if="currentTab.value == tab.value") {{ tab.name }}
          v-spacer(v-if="index != tabs.length - 1")

</template>

<script>

export default {
  name: 'Navigation',
  data: () => ({ 
    currentTab: {},
    echo: null,
    tabs: [
      { name: 'Utama', icon: '$icon-home', value: 'home', path: '/home' },
      { name: 'Waktu Solat', icon: '$icon-athan', value: 'athan', path: '/athan' },
      { name: 'Quran', icon: '$icon-quran', value: 'quran', path: '/quran' },
      { name: 'Solat', icon: '$icon-prayer', value: 'prayer', path: '/prayer' },
      { name: 'Lain-lain', icon: '$icon-others', value: 'others', path: '/others' },
    ]
  }),
  watch: {
    '$route.path' (val) {
      this.currentTab = this.tabs.filter(tab => tab.value === val.split('/')[1])[0]
      if (this.currentTab.value === 'home') {
        this.updateStatusBar(true)
      } else {
        this.updateStatusBar(false)
      }
    }
  },
  mounted () {
    if (!this.currentTab.path) {
      this.currentTab = this.tabs.filter(tab => tab.value === this.$route.path.split('/')[1])[0]
    }
    if (!this.currentTab) {
      this.currentTab = this.tabs[0]
      this.updateStatusBar(true)
    }
    if (this.$route.path === '/') {
      this.onTabChange(this.currentTab)
    }    
  },
  methods: {
    async updateStatusBar(isDark) {
      const data = JSON.stringify({ value: isDark })
      this.$plugins.updateEvent({ event: 'set_status_bar', jsonString: data })
    },
    onTabChange (tab) {
      this.$router.push(tab.path)
    }
  }
}
</script>
